<template>
  <Layout>
    <div v-if="employee" class="schedule mt-3">
      <div class="schedule__filters row">
        <h2 class="col-4">{{ employee.name }}</h2>
      </div>
      <b-card class="schedule__card">
        <div class="schedule__content">
          <b-form-group
            label="Timezone"
            label-for="time-zone"
            class="d-flex align-items-center required"
          >
            <b-form-select
              id="time-zone"
              v-model="timeZone"
              :options="zones"
              required
              class="ml-2 schedule__select"
            />
          </b-form-group>
          <div class="btn-group mb-2">
            <button @click="toOfficeFormat" class="schedule__save btn btn-primary">Default office schedule</button>
            <button @click="toRemoteFormat" class="schedule__save btn btn-primary">Default remote schedule</button>
            <button @click="clear" class="schedule__save btn btn-secondary">Clear</button>
          </div>
        </div>
        <table class="schedule__calendar calendar">
          <thead class="calendar__header">
          <tr>
            <th v-for="(day, index) in days" :key="index" class="calendar__header-item item">{{ day.name }}</th>
            <th class="calendar__header-item item time"></th>
          </tr>
          </thead>
          <tbody class="schedule__calendar__body">
          <tr v-for="(time, i) in 24" :key="time" class="calendar__body-hour">
            <td
              v-for="(day, index) in days"
              :key="index"
              class="calendar__body-item item"
              :class="{'calendar__body-item_active': selects.some(e => e.index === index && e.time === time)}"
              @mousemove="mouseMoveHandler(index, time)"
              @click="toggleHandler(index, time)"
            ></td>
            <td class="calendar__header-item item time">{{ formatTime(i) }}-{{ formatTime(time) }}</td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between mt-2 schedule__content">
          <button @click="openUser" class="schedule__save btn btn-secondary">Back</button>
          <button @click="save" class="schedule__save btn btn-primary">Save</button>
        </div>
      </b-card>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main.vue';
import { updateScheduleRequest } from '@/api/schedule'
export default {
  name: 'Schedule',
  components: {
    Layout,
  },
  data() {
    return {
      timeZone: 0,
      days: [
        { name: 'M' }, { name: 'T' }, { name: 'W' }, { name: 'Th' }, { name: 'F' }, { name: 'St' }, { name: 'S' },
      ],
      isMouseClicked: false,
      selects: [],
      firstX: null,
      firstY: null,
      prevX: null,
      prevY: null,
      lastX: null,
      lastY: null,
    }
  },
  computed: {
    zones() {
      const res = []
      for (let i = -12; i < 15; i++) {
        res.push({ text: `UTC${i >= 1 ? '+' + i : i ? i : '-' + i}`, value: i })
      }
      return res
    },
    employee() {
      return this.$store.getters["employees/employee"];
    },
  },
  methods: {
    mouseDownHandler () {
      this.isMouseClicked = true
    },
    mouseUpHandler () {
      this.isMouseClicked = false
      this.firstX = null
      this.firstY = null
      this.lastX = null
      this.lastY = null
    },
    toggleHandler (index, time) {
      const item = this.selects.findIndex(e => e.index === index && e.time === time)
      if (item > -1) {
        this.selects.splice(item, 1)
      } else {
        this.selects.push({index, time})
      }
    },
    mouseMoveHandler (index, time) {
      if (!this.isMouseClicked) {
        return
      }
      if (!this.firstX && !this.firstY) {
        this.firstX = time
        this.firstY = index
        this.lastX = time
        this.lastY = index
        this.prevX = time
        this.prevY = index
        if (index && time) {
          this.selects.push({index, time})
        }
        return
      }
      if (this.selects.some(e => e.index === index && e.time === time)) {
        return
      }
      if (this.prevX > time) {
        this.firstX = time
      } else if (this.prevX < time) {
        this.lastX = time
      }
      if (this.prevY > index) {
        this.firstY = index
      } if (this.prevY < index) {
        this.lastY = index
      }
      for (let i = this.firstY; i <= this.lastY; i++) {
        for (let j = this.firstX; j <= this.lastX; j++) {
          // console.log(this.selects.some(e => e.index === i && e.time === j), i, j)
          if (!this.selects.some(e => e.index === i && e.time === j)) {
            this.selects.push({index: i, time: j})
          }
        }
      }
      this.prevX = time
      this.prevY = index
    },
    async save() {
      const formData = new FormData()
      formData.append('timezone', this.timeZone)
      const arr = this.selects.sort((a, b) => a.index < b.index ? -1 : 1)
      arr.forEach((e) => {
        formData.append(`schedule[${e.index}][]`, e.time - 1)
      })
      try {
        await updateScheduleRequest(this.employee.uid, formData).then(async (res) => {
          if (res.code === 200) {
            await this.$router.push(`/employees/${this.$route.params.id}`)
          }
        })
        this.$showSuccessToast('Schedule successfully save')
      } catch (e) {
        console.error(e);
      }
    },
    formatTime (time) {
      const result = new Date((time || 0) * 1000).toISOString().substr(11, 8)
      return result.substr(6, 2) + ':00'
    },
    openUser () {
      this.$router.push(`/employees/${this.$route.params.id}`)
    },
    clear () {
      this.selects = []
    },
    toOfficeFormat () {
      this.selects = []
      for (let i = 0; i < 5; i++) {
        for (let j = 10; j < 19; j++) {
          if (j !== 14) {
            this.selects.push({ index: i, time: j })
          }
        }
      }
    },
    toRemoteFormat () {
      this.selects = []
      for (let i = 0; i < 5; i++) {
        for (let j = 15; j < 24; j++) {
          if (j !== 19) {
            this.selects.push({ index: i, time: j })
          }
        }
      }
    },
    calculateTime (hour, timezone) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return hour + 24 + timezone + 1
      }
      return (hour + timezone) % 24 + 1
    },
    calculateDay (day, timezone, hour) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return day - 1
      }
      if (hour + timezone + 1 > 23) {
        return (day + 1) % 7
      }
      return day
    }
  },
  async mounted() {
    document.addEventListener('mousedown', this.mouseDownHandler)
    document.addEventListener('mouseup', this.mouseUpHandler)
    await this.$store.dispatch('employees/getEmployeeById', this.$route.params.id);
    this.timeZone = -(new Date().getTimezoneOffset()/60)
    this.employee.schedule.forEach((e) => {
      if (!this.selects.some(e => e.index === this.calculateDay(e.day_of_week, e.timezone, e.hour) && e.time === this.calculateTime(e.hour, e.timezone))) {
        this.selects.push({ index: this.calculateDay(e.day_of_week, e.timezone, e.hour), time: this.calculateTime(e.hour, e.timezone) })
      }
      this.timeZone = e.timezone
    })
  },
  async beforeDestroy() {
    document.removeEventListener('mousedown', this.mouseDownHandler)
    document.removeEventListener('mouseup', this.mouseUpHandler)
  },
}
</script>

<style lang="scss" scoped>
.calendar {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.item {
  width: 50px;
  height: 24px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
}
.time {
  width: 150px;
  border: 1px solid #dbe0f9;
}
.calendar__body-item {
  background-color: #e7e7ee;
  cursor: pointer;
}
.calendar__body-item_active {
  background-color: #00be9b;
}
.calendar__header {
  background-color: var(--primary);
  color: var(--light);
}
.schedule__select {
  width: 200px;
  cursor: pointer;
}
.schedule__card {
  width: 520px;
}
</style>
